import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import { FiClock , FiUser , FiMessageCircle , FiHeart } from "react-icons/fi";
import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/HeaderFour"
import Footer from "../../component/footer/Footer";

class BlogDetails extends Component{
  constructor () {
    super()
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }
  openModal () {
    this.setState({isOpen: true})
  }
  render(){
    return(
      <React.Fragment>
        <PageHelmet pageTitle='Operative Umsetzung der Digitalstrategie für ein Logistikunternehmen' />
        <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

        {/* Start Breadcrump Area */}
        <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--7" data-black-overlay="7">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="blog-single-page-title text-center pt--100">

                <h2 className="title theme-gradient">
                Operative Umsetzung der Digitalstrategie für ein Logistikunternehmen
              </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Blog Details */}
        <div className="rn-blog-details pt--110 pb--70 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner-wrapper">
                  <div className="inner">
                    <p>Angesichts der raschen Digitalisierung der Logistikbranche möchten unsere Kunden ein strategisches Zielbild für die digitale Transformation entwickeln und in eine Implementierungs-Roadmap umwandeln. Um ein Zielbild zu erstellen, sollten wir zunächst zukünftige Technologie-, Markt- und Wettbewerbstrends analysieren. Die Bildungsabteilung sollte klare strategische Anweisungen, operative Verfahren und eine kontinuierliche Optimierung der Vertriebs- und Marketingaktivitäten verwenden, um das ungenutzte Potenzial auszuschöpfen. Relevante und zuverlässige KPIs und notwendige Mittel sollten verwendet werden, um den Geschäftserfolg zu kontrollieren und zu verbessern.
                    </p>
                    <h2>Projektergebnis und Kundennutzen</h2>
                    <p className="mt--40 h1">Ein koordiniertes und konsistentes Bild der strategischen Ziele ermöglicht es Management, Gesellschaftern und Mitarbeitern, eine gemeinsame Vorstellung von der digitalen Transformation und den daraus resultierenden Anforderungen zu haben. Anschließend kann die strategische Roadmap der IT- und Fachabteilungen die erforderlichen digitalen Maßnahmen klar planen. Durch klar definierte Verantwortlichkeiten und Entscheidungsprozesse wurde die Zusammenarbeit zwischen Fachabteilungen und IT-Abteilungen erheblich verbessert. Insgesamt hat die Umsetzung aller digitalen Maßnahmen unseren Kunden jedes Jahr Hunderttausende von Euro erspart.</p>
                    <h2>Herausforderung</h2>

                    <p>Dem Management fehlt ein Gesamtüberblick über mögliche digitale Ansätze, es besteht kein Konsens über Bewertungskriterien und es werden potenzielle Projekte auf der Zeit-Roadmap nicht priorisiert.</p>
                    <blockquote className="rn-blog-quote">Intern werden verschiedene digitale Methoden verwendet, jedoch mit unterschiedlichem Engagement. Obwohl einige Unternehmen die Digitalisierung ihres Kerngeschäfts aktiv fördern, verlassen sich andere immer noch hauptsächlich auf Papier.</blockquote>
                    <div className="blog-single-list-wrapper d-flex flex-wrap">
                      <div className="thumbnail">
                        <img className="w-100" src="/assets/images/blog/blog-single-01.png" alt="BLog Images"/>
                      </div>
                      <div className="content">
                        <h2>Vorgehen</h2>
                        <p>Zunächst wird der Grundstein für alle im strategischen Projekt identifizierten Optimierungsmaßnahmen gelegt: saubere, konsistente Kontrolle und Änderungen in der technischen Infrastruktur. Die Umsetzung dieser Punkte hat zu mehr Flexibilität und Kosteneffizienz bei der Lead-Generierung geführt, unabhängig vom Agentenprozess, und ermöglicht eine systematische Berücksichtigung der Effizienz aller Marketingaktivitäten. Anschließend wurden die ersten Optimierungsmaßnahmen für den Verkaufsprozess implementiert. Mit Hilfe solcher Papierkataloge und umfangreicher Tests hat sich die Anzahl der über Google Adwords generierten Vertriebs-Leads erhöht. Hier müssen wir besonders darauf achten, keine anderen Leads auf Kosten der Lead-Qualität zu erstellen, sondern deren Anzahl bei gleichbleibender Qualität zu erhöhen.</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Blog Details */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />

      </React.Fragment>
    )
  }
}
export default BlogDetails;